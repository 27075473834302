// src/node/index.ts
import fs from "fs";

// src/core.ts
import struct from "python-struct";

// src/utils.ts
var baseConvert = (num) => {
  return {
    from: function(radixFrom) {
      return {
        to: function(radixTo) {
          return parseInt(num, radixFrom).toString(radixTo);
        }
      };
    }
  };
};
var le32 = function(bits) {
  let result = 0;
  let offset = 0;
  for (let i = 0; i < 4; i++) {
    const byte = baseConvert(bits.slice(i, i + 1).toString("hex")).from(16).to(10);
    result |= Number(byte) << offset;
    offset += 8;
  }
  return result;
};
var le16 = function(bits) {
  const byteA = baseConvert(bits.slice(0, 1).toString("hex")).from(16).to(10);
  const byteB = baseConvert(bits.slice(1, 2).toString("hex")).from(16).to(10);
  return Number(byteA) | Number(byteB) << 8;
};

// src/core.ts
var extract = async ({
  seekChunk,
  destroyAll,
  generateIcon0 = true,
  generateParamSfo = true
}) => {
  if (!generateIcon0 && !generateParamSfo) {
    return;
  }
  const baseChunk = await seekChunk(0, 31);
  if (baseChunk.slice(0, 3 + 1).toString("utf-8") !== "\x7FCNT") {
    throw new Error("Invalid file format");
  }
  const totalTanleEntry = struct.unpack(">I", baseChunk.slice(16, 23 + 1))[0];
  const offsetTableEntry = struct.unpack(">I", baseChunk.slice(24, 31 + 1))[0];
  const { paramSfo, icon0 } = await getCustomTableEntryStruct({
    offset: offsetTableEntry,
    total: totalTanleEntry,
    generateIcon0,
    generateParamSfo,
    seekChunk
  });
  const getParamSfo = async () => {
    if (!paramSfo) {
      return;
    }
    const { offset, size } = paramSfo;
    const chunk = await seekChunk(offset, offset + size);
    const paramSfoHeader = getParamSfoHeader(chunk.slice(0, size));
    const paramSfoLabels = chunk.slice(le32(paramSfoHeader.labelPtr), size);
    const paramSfoData = chunk.slice(le32(paramSfoHeader.dataPtr), size);
    const data = {};
    let index = 20;
    for (let i = 0; i < le32(paramSfoHeader.sectionTotal); i++) {
      const paramSfoSection = getParamSfoSection(chunk.slice(index, size));
      const label = paramSfoLabels.toString("utf-8", le16(paramSfoSection.labelOffset), paramSfoLabels.length).split("\0")[0];
      let value = "";
      switch (paramSfoSection.dataType) {
        case ParamSfoSectionDataType.string:
          value = paramSfoData.toString(
            "utf-8",
            le32(paramSfoSection.dataOffset),
            le32(paramSfoSection.dataOffset) + le32(paramSfoSection.usedDataField) - 1
          );
          break;
        case ParamSfoSectionDataType.integer:
          value = le32(
            paramSfoData.slice(
              le32(paramSfoSection.dataOffset),
              le32(paramSfoSection.dataOffset) + le32(paramSfoSection.usedDataField)
            )
          );
          break;
        default:
          break;
      }
      data[label] = value;
      index += 16;
    }
    return data;
  };
  const getIcon0 = async () => {
    if (!icon0) {
      return;
    }
    const { offset, size } = icon0;
    return await seekChunk(offset, offset + size);
  };
  const promises = [];
  if (generateParamSfo) {
    promises.push(getParamSfo());
  }
  if (generateIcon0) {
    promises.push(getIcon0());
  }
  if (promises.length === 0) {
    return;
  }
  const res = await Promise.all(promises);
  destroyAll?.();
  return {
    paramSfo: generateParamSfo ? res?.[0] : void 0,
    icon0Raw: !generateParamSfo ? res?.[0] : res?.[1]
  };
};
var getPkgTableEntrySturct = (chunk) => {
  const data = struct.unpack(">IIIIII8x", chunk);
  return {
    id: data[0],
    filename_offset: data[1],
    flags1: data[2],
    flags2: data[3],
    offset: data[4],
    size: data[5]
  };
};
var getCustomTableEntryStruct = async ({
  offset,
  total,
  generateIcon0,
  generateParamSfo,
  seekChunk
}) => {
  const customTableEntryStruct = {};
  if (!generateParamSfo && !generateIcon0) {
    return customTableEntryStruct;
  }
  const chunk = await seekChunk(offset, offset + total * 32);
  for (let i = 0; i < total; i++) {
    const slice = chunk.slice(i * 32, i * 32 + 32);
    const pkgTableEntryStruct = getPkgTableEntrySturct(slice);
    if (generateParamSfo && pkgTableEntryStruct.id === 4096) {
      customTableEntryStruct.paramSfo = pkgTableEntryStruct;
      if (!generateIcon0) {
        break;
      }
    }
    if (generateIcon0 && pkgTableEntryStruct.id === 4608) {
      customTableEntryStruct.icon0 = pkgTableEntryStruct;
      if (!generateParamSfo) {
        break;
      }
    }
    if (customTableEntryStruct.paramSfo && customTableEntryStruct.icon0) {
      break;
    }
  }
  if (customTableEntryStruct.paramSfo || customTableEntryStruct.icon0) {
    return customTableEntryStruct;
  } else {
    throw new Error("customTableEntryStruct is not found");
  }
};
var getParamSfoHeader = (chunk) => {
  return {
    data: chunk,
    magic: le32(chunk.slice(0, 4)),
    rfu000: le32(chunk.slice(4, 8)),
    labelPtr: chunk.slice(8, 12),
    dataPtr: chunk.slice(12, 16),
    sectionTotal: chunk.slice(16, 20)
  };
};
var ParamSfoSectionDataType = /* @__PURE__ */ ((ParamSfoSectionDataType2) => {
  ParamSfoSectionDataType2[ParamSfoSectionDataType2["binary"] = 0] = "binary";
  ParamSfoSectionDataType2[ParamSfoSectionDataType2["string"] = 2] = "string";
  ParamSfoSectionDataType2[ParamSfoSectionDataType2["integer"] = 4] = "integer";
  return ParamSfoSectionDataType2;
})(ParamSfoSectionDataType || {});
var getParamSfoSection = (chunk) => {
  return {
    data: chunk,
    labelOffset: chunk.slice(0, 2),
    rfu001: chunk.slice(2, 3),
    dataType: parseInt(chunk.toString("hex", 3, 4)),
    usedDataField: chunk.slice(4, 8),
    sizeDataField: chunk.slice(8, 12),
    dataOffset: chunk.slice(12, 16)
  };
};

// src/types.ts
var Ps4PkgAppType = /* @__PURE__ */ ((Ps4PkgAppType2) => {
  Ps4PkgAppType2[Ps4PkgAppType2["UNKNOWN"] = 0] = "UNKNOWN";
  Ps4PkgAppType2[Ps4PkgAppType2["PaidStandaloneFull"] = 1] = "PaidStandaloneFull";
  Ps4PkgAppType2[Ps4PkgAppType2["Upgragable"] = 2] = "Upgragable";
  Ps4PkgAppType2[Ps4PkgAppType2["Demo"] = 3] = "Demo";
  Ps4PkgAppType2[Ps4PkgAppType2["Freemium"] = 4] = "Freemium";
  return Ps4PkgAppType2;
})(Ps4PkgAppType || {});
var Ps4PkgCategory = /* @__PURE__ */ ((Ps4PkgCategory2) => {
  Ps4PkgCategory2["GameDigital"] = "gd";
  Ps4PkgCategory2["Digital"] = "gdn";
  Ps4PkgCategory2["GameApplicationPatch"] = "gp";
  Ps4PkgCategory2["ApplicationPatch"] = "gpn";
  Ps4PkgCategory2["AdditionalContent"] = "ac";
  Ps4PkgCategory2["BluRayDisc"] = "bd";
  Ps4PkgCategory2["GameContent"] = "gc";
  Ps4PkgCategory2["SystemApp"] = "gda";
  Ps4PkgCategory2["BigApp"] = "gdc";
  Ps4PkgCategory2["BGApp"] = "gdd";
  Ps4PkgCategory2["MiniApp"] = "gde";
  Ps4PkgCategory2["VideoServiceWebApp"] = "gdk";
  Ps4PkgCategory2["PSCloudBetaApp"] = "gdl";
  Ps4PkgCategory2["PS2"] = "gdO";
  Ps4PkgCategory2["BigAppPatch"] = "gpc";
  Ps4PkgCategory2["BGAppPatch"] = "gpd";
  Ps4PkgCategory2["MiniAppPatch"] = "gpe";
  Ps4PkgCategory2["VideoServiceWebAppPatch"] = "gpk";
  Ps4PkgCategory2["PSCloudBetaAppPatch"] = "gpl";
  Ps4PkgCategory2["SaveData"] = "sd";
  return Ps4PkgCategory2;
})(Ps4PkgCategory || {});

// src/node/index.ts
var createSeek = (pkgFilePath) => {
  const streams = [];
  const seek = (offset, whence) => {
    const stream = fs.createReadStream(pkgFilePath, {
      start: offset,
      end: whence
    });
    streams.push(stream);
    return stream;
  };
  return {
    seekChunk: async (offset, whence) => {
      const stream = seek(offset, whence);
      let totalChunk = Buffer.from([]);
      return await new Promise((resolve, reject) => {
        if (whence) {
          stream.on("data", (chunk) => {
            totalChunk = Buffer.concat([totalChunk, chunk]);
          });
          stream.on("end", () => {
            resolve(totalChunk);
          });
        } else {
          stream.once("data", resolve);
        }
        stream.on("error", reject);
      });
    },
    destroyAll() {
      streams.forEach((stream) => {
        stream.destroy();
      });
    }
  };
};
var getPs4PkgInfo = async (pkgFilePath, options) => {
  const { seekChunk, destroyAll } = createSeek(pkgFilePath);
  const res = await extract({ seekChunk, destroyAll, ...options });
  if (res?.icon0Raw && options?.generateBase64Icon) {
    res.icon0 = `data:image/png;base64,` + res.icon0Raw.toString("base64");
  }
  return res;
};
export {
  Ps4PkgAppType,
  Ps4PkgCategory,
  getPs4PkgInfo
};
